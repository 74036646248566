<template>
    <div class="nudeChatDetails">
        <!-- 裸聊轮播图 -->
        <van-swipe class="chatSwiper" :autoplay="3000" @change="onChange">
            <van-swipe-item v-for="(item, index) in imgList" :key="index" @click="previewImg">
                <ImgDecypt class="nudeChatImg" :src="item"></ImgDecypt>
            </van-swipe-item>
            <template #indicator>
                <div class="custom-indicator">{{ (current + 1) + '/' + imgList.length }}</div>
            </template>
        </van-swipe>
        <!-- 公告 -->
        <van-notice-bar scrollable background="#f42b1b" color="#FFFFFF" speed="40" :text="announcement" />
        <div class="detailBox">
            <!-- 标题 -->
            <div class="detail_title">{{nudeChatDetail.title}}</div>
            <!-- 认证 -->
            <div class="certificationBox">
                <svg-icon class="shield" iconClass="icn_shield"></svg-icon>
                <div class="certification_title">官方认证</div>
                <div class="certification_instruc">妹妹信息平台已验证，真实可靠！</div>
            </div>
            <!-- 联系方式 -->
            <div class="subheading mt10">
                <p></p>联系方式
            </div>

            <div class="contactMethod" v-if="nudeChatDetail.isBuy && nudeChatDetail.confirmCode">
                <div class="contactMethod_title">商家确认码：</div>
                <div class="confirmCode">{{nudeChatDetail.confirmCode}}</div>
                <div class="contactMethod_line mb8"></div>
                <div class="contactMethod_title">商家联系方式：</div>
                <div class="contactBox">
                    <div class="contactMethod_content">{{nudeChatDetail.contact}}</div>
                </div>
                <div class="contactMethod_line"></div>
                <div class="precautions mt10">
                    <div>注意事项：</div>
                    <div class="precautions_content">
                        1.联系商家，将确认码发给对方。<br><br>
                        2.若是遇到与描述不符，请直接到平台举报。
                    </div>
                </div>
            </div>
            <div class="encrypContactBox" v-else>
                <span class="encrypContactInfo">{{nudeChatDetail.contact}}</span>
                <span>连线后获得联系方式</span>
            </div>
            <!-- 基本资料 -->
            <div class="subheading">
                <p></p>基本信息
            </div>
            <div class="basicInfoBox">
                <div class="infoBox mb16">
                    <div class="infoBox_icon girlIcon"></div>
                    <div class="infoBox_title">妹子资料：</div>
                    <div class="infoBox_Item">{{nudeChatDetail.age ? `${nudeChatDetail.age}岁` : ''}}</div>
                    <div class="infoBox_Item">{{nudeChatDetail.height ? `${nudeChatDetail.height}cm` : ''}}</div>
                    <div class="infoBox_Item">{{nudeChatDetail.weight ? `${nudeChatDetail.weight}kg` : ''}}</div>
                    <div class="infoBox_Item">{{getCup(nudeChatDetail.cup)}}</div>
                </div>
                <div class="infoBox mb16" v-if="nudeChatDetail.serviceTime">
                    <div class="infoBox_icon timeIcon"></div>
                    <div class="infoBox_title">连线时间：</div>
                    <div class="infoBox_Item">{{nudeChatDetail.serviceTime}}</div>
                </div>
                <div class="infoBox" v-if="nudeChatDetail.detail">
                    <div class="infoBox_icon detailIcon"></div>
                    <div class="infoBox_title">详情介绍：</div>
                    <div class="infoBox_Item">{{nudeChatDetail.detail}}</div>
                </div>
            </div>
            <!-- 广告轮播图 -->
            <AdvSwiper class="advSwiper" :advList="louFengAdvList"></AdvSwiper>
        </div>

        <!-- 返回按钮 -->
        <div class="backBox" @click="$router.go(-1)">
            <div class="backBtn"></div>
        </div>

        <!-- 举报按钮 -->
        <div class="reportBtn" @click="report"><i></i></div>

        <!-- 提示弹窗 -->
        <van-overlay :show="showTip" @click="showTip = false" :z-index="20">
            <div class="wrapper">
                <div class="blockBox">
                    <div style="font-size: 20px;font-weight: 800;">温馨提示</div>
                    <div style="font-size:14px">购买后才能举报</div>
                    <div class="knowBtn">知道了</div>
                </div>
            </div>
        </van-overlay>

        <!-- 图片预览 -->
        <van-image-preview v-model="showPreview" :images="previewList" @change="onChangePreview" closeable>
            <template v-slot:previewIndex>第{{ previewIndex }}页</template>
        </van-image-preview>

        <!-- 连接裸聊按钮 -->
        <div class="connectBtn" @click="connectChat">
            <svg-icon class="chatIcon" iconClass="icn_trade"></svg-icon>
            <div>连线裸聊</div>
        </div>

        <!-- 购买弹出层 -->
        <van-popup class="buyPopup" v-model="showBuy" round position="bottom" @close="close">
            <div class="popup_title">果币支付</div>
            <div class="popup_amountBox">
                <svg-icon class="popup_goldIcon" iconClass="nudeChatCoin"></svg-icon>
                <div class="popup_amount">{{payAmount}}</div>
            </div>
            <div class="popLine"></div>
            <div class="popSubtitle">选中时长</div>
            <van-radio-group v-model="payIndex" direction="horizontal" class="durationRadio" @change="changeTime">
                <div class="radioBox" v-for="(item, index) in durationList" :key="index">
                    <div class="radioItem">{{item.serviceTime}}分钟</div>
                    <van-radio :name="index" checked-color="#EB2A2A"></van-radio>
                </div>
            </van-radio-group>
            <div class="thickLine"></div>
            <div class="popSubtitle">我的果币</div>
            <div class="fBalance" @click="toRecharge">
                <div>余额：{{fBalance}}</div>
                <div class="rightArrow"></div>
            </div>
            <div class="proportion">比例 1 : 1</div>
            <div class="payBtn" @click="buyNudeChat">{{fBalance >= payAmount ? '确认支付' : '果币余额不足，立即充值'}}</div>
        </van-popup>
    </div>
</template>

<script>
    import {
        queryNudChatDetail,
        buyNudeChat
    } from '@/api/nudeChat.js';
    import {
        mapGetters
    } from "vuex";
    import {
        imgDecypt
    } from "@/api/app.js";
    import {
        getAdItem,
        AdType
    } from "@/utils/getAdv.js";
    import AdvSwiper from '@/components/AdvSwiper/index';
    export default {
        components: {
            AdvSwiper
        },
        data() {
            return {
                nudeChatDetail: {}, //楼凤详情
                imgList: [], //裸聊轮播图片列表
                current: 0, //当前裸聊轮播下标
                announcement: "妹妹信息平台都已验证，真实可靠，如遇货不对版，请向平台举报，最高全额赔付，连线时请注意隐私，务必使用平台支付，私下交易被骗，平台概不负责.", //公告
                showPreview: false, //图片预览状态
                previewList: [], //预览图片列表
                previewIndex: 0, //预览图片下标
                louFengAdvList: [], //广告轮播图
                showTip: false, //提示弹窗状态
                showBuy: false, //购买弹出层
                fBalance: 0, //果币余额
                durationList: [], //时长
                requstLoading: false, //loading状态
                payIndex: 0, //选中支付方式下标
                payAmount: 0, //支付金额
            }
        },
        computed: {
            ...mapGetters({
                logicCfg: "logicCfg",
            }),
        },
        created() {
            this.getLouFengAdvList();
            this.queryNudChatDetail();
        },
        methods: {
            // 获取楼凤广告
            getLouFengAdvList() {
                this.louFengAdvList = getAdItem(AdType.loufeng);
            },
            // 查询裸聊详情
            async queryNudChatDetail() {
                let ret = await this.$Api(queryNudChatDetail, {
                    id: this.$route.query.cid,
                });
                if (ret && ret.code == 200) {
                    this.nudeChatDetail = ret.data;
                    this.imgList = ret.data.imageCover;

                    this.durationList = ret.data.services.sort((i, j) => {
                        return i.serviceTime - j.serviceTime;
                    });
                    this.payAmount = this.durationList[0].price;
                    this.imgDecyption(ret.data.imageCover)
                }
            },

            // 获取钱包信息
            async getWalletInfo() {
                await this.$store.dispatch("user/setWallet");
                this.fBalance = this.$store.getters.walletInfo.guoBi;
            },

            // 图片解密
            imgDecyption(imgArr) {
                this.previewList = [];
                let imgDomain = this.logicCfg.imgDomain;
                if (!imgDomain) return;
                if (imgArr && imgArr.length > 0) {
                    imgArr.forEach(i => {
                        imgDecypt(imgDomain + i).then((url) => {
                            this.previewList.push(url);
                        });
                    });
                }
            },

            // 切换轮播图
            onChange(index) {
                this.current = index;
            },

            // 点击预览图片
            previewImg() {
                if (this.previewList.length > 0) {
                    this.showPreview = true;
                }
            },

            // 切换预览图片
            onChangePreview(index) {
                this.previewIndex = index;
            },

            // 举报
            report() {
                if (!this.nudeChatDetail.isBuy) {
                    this.showTip = true;
                } else {
                    this.$router.push({
                        path: "/nudeChatReport",
                        query: {
                            id: this.nudeChatDetail.id
                        },
                    });
                }
            },

            // 点击连线
            connectChat() {
                this.getWalletInfo()
                this.showBuy = true;
                // let req = {
                //     id: this.nudeChatDetail.id,
                // }
                // this.requstLoading = true;
                // let ret = await this.$common.myAsyncFn(queryNudChatPay, req, true);
                // if (ret && ret.code == 200) {
                //     this.fBalance = ret.data.balance;
                //     this.durationList = ret.data.list;
                //     this.payAmount = this.durationList[this.payIndex].price;
                //     this.showBuy = true;
                // }
                // this.requstLoading = false;
            },

            // 改变时长
            changeTime() {
                this.payAmount = this.durationList[this.payIndex].price;
            },

            // 前往充值页面
            toRecharge() {
                let vipGrap = {
                  type: "user"
                }
                sessionStorage.setItem('vipGrap', JSON.stringify(vipGrap));
                this.$router.push({
                    path: '/wallet',
                    query: {
                        t: 'nudeChat'
                    }
                })
            },

            // 购买裸聊
            buyNudeChat() {
                if (this.payAmount > this.fBalance) {
                    this.toRecharge();
                    return;
                }
                this.goldExchange();
            },

            // 果币购买
            async goldExchange() {
                let req = {
                    nudeChatId: this.nudeChatDetail.id,
                    ticketId: this.durationList[this.payIndex].serviceId,
                }
                // this.requstLoading = true;
                let ret = await this.$Api(buyNudeChat, req);
                if (ret && ret.code == 200) {
                    this.$toast('购买成功');
                    this.queryNudChatDetail();
                    this.showBuy = false;
                } else {
                    this.$toast(ret.tip || '购买失败，请稍后再试')
                }
                // this.requstLoading = false;
            },

            // 关闭支付弹出层
            close() {
                this.fBalance = 0; //果币余额
                // this.durationList = []; //时长
                // this.requstLoading = false; //loading状态
                this.payIndex = 0; //选中支付方式下标
                // this.payAmount = 0; //支付金额
            },

            // 获取cup /0=(C罩杯) 1=(D罩杯) 2=(E罩杯) 3=(F罩杯)
            getCup(type) {
                let str = '';
                switch (type) {
                    case 0:
                        str = 'C罩杯';
                        break;
                    case 1:
                        str = 'D罩杯';
                        break;
                    case 2:
                        str = 'E罩杯';
                        break;
                    case 3:
                        str = 'F罩杯';
                        break;
                    default:
                        break;
                }
                return str;
            },
        },
    }
</script>

<style lang='scss' scoped>
    .nudeChatDetails {
        height: 100%;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        .chatSwiper {
            width: 100%;
            height: 465px;

            .nudeChatImg {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .custom-indicator {
                position: absolute;
                right: 16px;
                bottom: 7px;
                padding: 0 10px;
                font-size: 16px;
                height: 22px;
                line-height: 22px;
                border-radius: 22px;
                background: rgba(0, 0, 0, 0.31);
                color: $white1;
            }
        }

        .van-notice-bar {
            height: 29px;
            font-size: 13px;
            padding: 0;
        }

        .detailBox {
            padding: 0 10px;

            .detail_title {
                font-size: 18px;
                margin: 10px 0 16px;
                font-weight: 600;
            }

            .certificationBox {
                width: 100%;
                height: 65px;
                background: #FFFFFF;
                box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
                border-radius: 4px;
                box-sizing: border-box;
                display: flex;
                align-items: center;

                .shield {
                    width: 38px;
                    height: 38px;
                    margin-left: 16px;
                }

                .certification_title {
                    font-size: 15px;
                    margin-left: 8px;
                }

                .certification_instruc {
                    font-size: 11px;
                    margin-left: 15px;
                }
            }

            .subheading {
                font-size: 16px;
                display: flex;
                align-items: center;

                p {
                    width: 3px;
                    height: 15px;
                    background: $vermillion;
                    margin-right: 3px;
                }
            }

            .contactMethod {
                width: 100%;
                padding: 4px 8px 13px;
                box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
                margin: 12px 0 10px;
                box-sizing: border-box;

                .contactMethod_line {
                    height: 1px;
                    opacity: 0.5;
                    transform: scaleY(-1);
                    background-image: linear-gradient(116deg, rgba(255, 255, 255, 0.00) 0%, #EB2A2A 47%, rgba(255, 255, 255, 0.00) 95%);
                }

                .contactMethod_title {
                    font-size: 13px;
                }

                .confirmCode {
                    color: #FF0000;
                    font-size: 30px;
                    text-align: center;
                    margin-bottom: 7px;
                }

                .precautions {
                    display: flex;
                    font-size: 11px;
                    // .precautions_content {
                    //     line-height: 20px;
                    // }
                }

                .contactBox {
                    text-align: center;
                    margin: 5px 0 10px;

                    .contactMethod_content {
                        font-size: 13px;
                        display: inline-block;
                        text-align: left;
                        white-space: pre-line;
                    }
                }
            }

            .encrypContactBox {
                width: 100%;
                height: 27px;
                margin: 16px 0;
                padding: 0 22px 0 10px;
                box-sizing: border-box;
                box-shadow: 0 1px 3px #ebebeb;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .encrypContactInfo {
                    font-size: 13px;
                    color: $vermillion;
                }
            }

            .basicInfoBox {
                width: 100%;
                padding: 11px 17px;
                box-sizing: border-box;
                box-shadow: 0 1px 6px rgba(0, 0, 0, 0.16);
                margin: 12px 0 20px;

                .infoBox {
                    display: flex;

                    .infoBox_icon {
                        width: 11px;
                        height: 11px;
                        margin-right: 11px;
                    }

                    .girlIcon {
                        background: url('../../../assets/png/girlIcon.png');
                        background-size: 100% 100%;
                    }

                    .timeIcon {
                        background: url('../../../assets/png/timeIcon.png');
                        background-size: 100% 100%;
                    }

                    .detailIcon {
                        background: url('../../../assets/png/detailIcon.png');
                        background-size: 100% 100%;
                    }

                    .infoBox_title {
                        // margin-right: 2px;
                        font-size: 14px;
                        font-weight: 600;
                    }

                    .infoBox_Item {
                        font-size: 13.5px;
                        margin-right: 2px;
                        color: #515151;
                        /* white-space: pre-wrap; */
                        word-wrap: break-word;
                        white-space: pre-line;
                        max-width: 220px;
                    }
                }

            }

            .advSwiper {
                margin-bottom: 88px;
            }
        }

        .connectBtn {
            background-image: linear-gradient(90deg, #F5164E 0%, #FF6538 100%, #F54404 100%);
            position: fixed;
            bottom: 20px;
            left: 50%;
            transform: translate(-50%, 0);
            width: 343px;
            height: 44px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 600;
            border-radius: 44px;
            color: $white1;
            z-index: 2;

            .chatIcon {
                width: 36px;
                height: 24px;
                margin-right: 9.5px;
            }
        }

        .backBox {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background: rgba(147, 147, 147, 0.34);
            position: fixed;
            top: 5px;
            left: 11px;
            display: flex;
            align-items: center;
            justify-content: center;

            .backBtn {
                width: 10px;
                height: 10px;
                margin-left: 5px;
                border-bottom: 2.5px solid #FFFFFF;
                border-left: 2.5px solid #FFFFFF;
                transform: rotate(45deg);
            }
        }

        .reportBtn {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background: rgba(147, 147, 147, 0.34);
            position: fixed;
            top: 5px;
            right: 11px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
                width: 18px;
                height: 18px;
                background: url('../../../assets/png/warning.png') center center;
                // background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }

        .buyPopup {
            border-radius: 15px 15px 0 0;
            padding: 22px 16px 30px;
            box-sizing: border-box;

            .popup_title {
                font-size: 20px;
                font-weight: 600;
                text-align: center;
            }

            .popup_amountBox {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 56px;
                margin: 16px 0 6px;

                .popup_goldIcon {
                    width: 40px;
                    height: 40px;
                }

                .popup_amount {
                    font-size: 40px;
                    color: #EB2A2A;
                    margin-left: 7px;
                }
            }

            .popLine {
                width: 100%;
                height: 1px;
                opacity: 0.5;
                background-image: linear-gradient(116deg, rgba(255, 255, 255, 0.00) 0%, #EB2A2A 47%, rgba(255, 255, 255, 0.00) 95%);
            }

            .popSubtitle {
                font-size: 16px;
                color: #000000;
                font-weight: 600;
                margin: 16px 0;
            }

            .durationRadio {
                justify-content: space-around;
                margin-bottom: 20px;
            }

            .radioBox {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                width: 60px;

                .radioItem {
                    color: #000000;
                    font-weight: 600;
                    font-size: 16px;
                    margin-bottom: 10px;
                }
            }

            .radioBox>>>.van-radio__icon {
                font-size: 16px;
            }

            .radioBox>>>.van-radio {
                margin: 0;
            }

            .thickLine {
                width: 100%;
                height: 3px;
                opacity: 0.5;
                transform: scaleY(-1);
                background-image: linear-gradient(116deg, rgba(255, 255, 255, 0.00) 0%, #EB2A2A 47%, rgba(255, 255, 255, 0.00) 95%);
            }

            .fBalance {
                display: flex;
                justify-content: center;
                align-items: center;
                color: #EB2A2A;
                font-size: 20px;
                font-weight: 600;

                .rightArrow {
                    width: 10px;
                    height: 10px;
                    display: inline-block;
                    border-top: 2.5px solid #EB2A2A;
                    border-right: 2.5px solid #EB2A2A;
                    transform: rotate(45deg);
                    margin-left: 10px;
                    margin-bottom: 2px;
                }
            }

            .proportion {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 12px;
                color: rgba(0, 0, 0, 0.5);
                margin-top: 6px;
            }

            .payBtn {
                width: 300px;
                height: 40px;
                margin: 65px auto 0;
                background-image: linear-gradient(90deg, #F5164E 0%, #FF6538 100%, #F54404 100%);
                box-shadow: 0px 6px 8px 0px rgba(248, 44, 44, 0.4);
                border-radius: 20px;
                text-align: center;
                line-height: 40px;
                font-size: 16px;
                font-weight: 600;
                color: $white1;
            }

        }

        .mb8 {
            margin-bottom: 8px;
        }

        .mt10 {
            margin-top: 10px;
        }

        .mb16 {
            margin-bottom: 16px;
        }

        // 提示框
        .wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            .blockBox {
                width: 280px;
                height: 150px;
                background-color: #fff;
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                align-items: center;

                .knowBtn {
                    width: 150px;
                    height: 30px;
                    background-color: line;
                    background-image: linear-gradient(to right, rgb(234, 42, 42), rgb(239, 169, 58));
                    border-radius: 17px;
                    text-align: center;
                    line-height: 30px;
                    color: white;
                }
            }
        }

    }
</style>